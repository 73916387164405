import { Box } from "@mui/system";
import ApiButton from "../../../components/apibutton/apibutton";

export default function ActionsCard() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <ApiButton
        endpoint="predict/buildclicktables"
        responseRequired={true}
        label="Build click tables"
      >
        Build click tables
      </ApiButton>
    </Box>
  );
}
