import { Box, Divider, Typography } from "@mui/material";

export default function TribesReport(props) {
  const { id } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        width: "450px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant="h4" color="primary">
          {id}
        </Typography>
      </Box>
    </Box>
  );
}
