import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

function getLabel(params) {
  const result = JSON.parse(params.row.labels);

  var returnString = "";
  if (result) {
    result.forEach((label, i) => {
      label.labels.forEach((label) => {
        returnString += `${label},`;
      });
    });
  }
  return returnString;
}

const columns = [
  {
    field: "id",
    headerName: "id",
    width: 72,
    type: "number",
    editable: false,
  },

  {
    field: "url",
    headerName: "URL",
    width: 500,
    editable: false,
  },
  {
    field: "labels",
    headerName: "labels",
    width: 220,
    valueGetter: getLabel,
    editable: false,
  },
  {
    field: "total",
    headerName: "Clicks",
    width: 100,
    editable: false,
  },
];

function CustomFooterTotalComponent(props) {
  return (
    <Box sx={{ padding: "10px", display: "flex" }}>
      Total Clicks: {props.totalClicks}
    </Box>
  );
}

export default function URLTable(props) {
  const { rows, selectedRows, totalClicks } = props;
  const totalRows = rows.length;
  console.log(totalRows);
  return (
    <DataGrid
      autoHeight
      rows={rows}
      columns={columns}
      componentsProps={{
        footer: { totalClicks, totalRows },
      }}
      disableSelectionOnClick
      selectionModel={selectedRows}
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "asc" }],
        },
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
    />
  );
}
