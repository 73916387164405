import { Routes, Route } from "react-router-dom";
import CTDAppBar from "../../appbar/appbar";
import Menu from "../../menu/menu";
import { Box } from "@mui/system";
import GlobalStateContext from "../../globalstate/globalStateContext";
import { Typography } from "@mui/material";
// content
import Dashboard from "../../components/dashboard/dashboard";
import ProtectedRoute from "../../auth/protectedroute";
import { useContext, useEffect } from "react";
// Icons
import SubjectIcon from "@mui/icons-material/Subject";
import { CommonRoutes } from "../../menu/common";
import SubjectHeroLaunch from "./launch";

const menuItems = [
  {
    name: "Launch subject hero",
    link: "launch",
    Icon: SubjectIcon,
    index: 0,
  },
];

export default function SubjectHero() {
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    global.setState((prevState) => ({
      ...prevState,
      application: "subjecthero",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CTDAppBar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            width: "250px",
          }}
        >
          <Menu menuItems={menuItems} common />
        </Box>

        <Box
          sx={{
            marginTop: "100px",
            display: "flex",
            width: "80%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {CommonRoutes}
          <Routes>
            <Route
              path="/"
              exact
              element={
                <ProtectedRoute>
                  {global.state.wssConnected && <Dashboard />}
                  {!global.state.wssConnected && (
                    <Box>
                      <Typography>
                        No real time service found - please refresh your browser
                        and if there is still no green cog on your screen please
                        contact iota-ML.
                      </Typography>
                    </Box>
                  )}
                </ProtectedRoute>
              }
            />
            <Route
              path="launch"
              exact
              element={
                <ProtectedRoute>
                  <SubjectHeroLaunch />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
