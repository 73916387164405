import { Routes, Route } from "react-router-dom";

import CTDAppBar from "../../appbar/appbar";
import CTDLogo from "../../components/logo/logo";

import Drawer from "@mui/material/Drawer";

import Menu from "../../menu/menu";
import { Box } from "@mui/system";

import GlobalStateContext from "../../globalstate/globalStateContext";

import { Typography } from "@mui/material";

// content
import Dashboard from "../../components/dashboard/dashboard";
import ProtectedRoute from "../../auth/protectedroute";
import { useContext, useEffect } from "react";
// Icons
// Icons

import SwitchAccessShortcutIcon from "@mui/icons-material/SwitchAccessShortcut";
import ActivateTribes from "./activatetribes";
import { CommonRoutes } from "../../menu/common";

const menuItems = [
  {
    name: "Activate",
    link: "activatetribes",
    Icon: SwitchAccessShortcutIcon,
    index: 0,
  },
];
export default function Tribes() {
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    console.log("tribes called");
    global.setState((prevState) => ({
      ...prevState,
      application: "tribes",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CTDAppBar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            width: "250px",
          }}
        >
          <Menu menuItems={menuItems} common />
        </Box>

        <Box
          sx={{
            marginTop: "100px",
            display: "flex",
            width: "80%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {CommonRoutes}
          <Routes>
            <Route
              path="/"
              exact
              element={
                <ProtectedRoute>
                  {global.state.wssConnected && <Dashboard />}
                  {!global.state.wssConnected && (
                    <Box>
                      <Typography>
                        No real time service found - please refresh your browser
                        and if there is still no green cog on your screen please
                        contact iota-ML.
                      </Typography>
                    </Box>
                  )}
                </ProtectedRoute>
              }
            />
            <Route
              path="activatetribes"
              exact
              element={
                <ProtectedRoute>
                  <ActivateTribes />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
