import { Box, Typography } from "@mui/material";
import CTResponsiveBar from "../../../components/nivo/barchart";
import CTVenn from "../../../components/reaviz/venn";

const vennData = [
  { key: ["A"], data: 12 },
  { key: ["B"], data: 12 },
  { key: ["A", "B"], data: 2 },
];
const dataChart = [
  {
    treatment: "A",
    A: 18,
    B: 23,
    AColor: "hsl(189, 70%, 50%)",
  },
  {
    treatment: "B",
    A: 15,
    B: 23,
    BColor: "hsl(45, 70%, 50%)",
  },
];

export default function CustomerBehaviours(props) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          Segments
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            /*  border: "thin lightgray solid",*/
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          <CTResponsiveBar
            data={dataChart}
            groupMode="grouped"
            layout="horizontal"
            height="200px"
            width="300px"
            index="treatment"
            keys={["A", "B"]}
            axisLeft={{
              format: (value) => `${value}%`,
              legend: "Outcome",
              legendPosition: "middle",
              legendOffset: -40,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          Intersect
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            /*  border: "thin lightgray solid",*/
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          <CTVenn height={300} width={300} data={vennData} />
        </Box>
      </Box>
    </Box>
  );
}
