//https://smartdevpreneur.com/the-ultimate-guide-to-customizing-material-ui-datagrid/


import { Box } from "@mui/system";
import {
  Alert,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import CTResponsivePie from "../../components/nivo/pie";
import URLTreeView from "./urltreeview";
import SendToApi from "../../api/sendtoapi";
import { useEffect, useState } from "react";
import findNestedObject from "../../utils/RecursiveSearch";
import URLTable from "./urlTable";
import TabPanels from "../../components/tabpanel";
import MouseIcon from "@mui/icons-material/Mouse";
import PeopleIcon from "@mui/icons-material/People";
import CalculateIcon from "@mui/icons-material/Calculate";
import LabelIcon from "@mui/icons-material/Label";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import LabelOffIcon from "@mui/icons-material/LabelOff";
import HttpIcon from "@mui/icons-material/Http";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";


export default function Labeller() {
  const [data, setData] = useState({}); // data for split rows
  const [urlRows, seturlRows] = useState(); // the rows of complete url strings
  const [selectedUID, setSelectedUID] = useState(false); // the selected node
  const [labelled, setLabelled] = useState({}); // the set of labels per row sent on submit
  const [label, setLabel] = useState([]); // the current set label from the autocomplete array of labels
  const [totals, setTotals] = useState({}); // the node totals
  const [labelTotals, setLabelTotals] = useState({}); // the complied list of labels and totals
  const [pieData, setPieData] = useState(false); // piechart data
  const [selectedRows, setSelectedRows] = useState([]); // use to highlight rows selected from tree
  const [searchOptions, setSearchOptions] = useState(false); // search terms
  const [expand, setExpand] = useState([]); // used to expand tree based on search term
  const [availableLabels, setAvailableLabels] = useState(false);
  const [totalClicks, setTotalClicks] = useState(false);
  const [labelSubmitted, setLabelSubmitted] = useState(false);
  const [pieTotal, setPieTotal] = useState(0);
  const [centerText, setCenterText] = useState(false);
  const [totalCustomers, setTotalCustomers] = useState(false);
  const [clicksPerCustomer, setClicksPerCustomer] = useState(false);
  const [numberOfLabels, setNumberOfLabels] = useState(false);
  const [noLabelCount, setNoLabelCount] = useState(false);
  const [nillLabelCount, setNillLabelCount] = useState(false);

  function handleNodeSearch(node) {
    if (!node) {
      setExpand([]);
      return;
    }
    //console.log(`node.uid ${node.uid}`);
    //console.log(data);
    // need to split on ; to find both paths
    const uids = node.uid.split(";");
    var _expand = [];
    uids.forEach((u) => {
      const id = u.split("-");
      var depth = id[1];
      const url = id[0];
      while (depth > -1) {
        const parent = `${url}-${depth--}`;
        const result = findNestedObject(data, "uid", parent, true);
        //console.log(result);
        if (result) {
          _expand.push(result.uid);
        }
      }
      setExpand(_expand);
    });
  }

  /**
  // The node is selected
   */
  function handleSelect(node) {
    setLabelSubmitted(false);
    setLabel([]);
    //console.log(node);
    const uid = node.uid;
    if (node) {
      setTotals({
        total: node.total,
        unique: node.total_unique,
        depth: node.depth,
        uid: node.uid,
      }); // assign the node variables
    }
    setSelectedUID(uid);
    var _selectedRows = [];
    uid.split(",").forEach((n) => {
      const v = n.slice(0, n.indexOf("-"));
      _selectedRows.push(v);
    });
    setSelectedRows(_selectedRows);

    if (typeof node.label === "undefined") {
      //setLabel("");
    } else {
      //console.log(node.label);
      setLabel(node.label);
    }
  }

  const [fetchedData, setFetchedData] = useState(false);
  useEffect(() => {
    if (!totalClicks) return;
    if (!totalCustomers) return;
    setClicksPerCustomer((totalClicks / totalCustomers).toFixed(2));
  }, [totalClicks, totalCustomers]);

  // get the available labels from the API
  useEffect(() => {
    (async () => {
      var values = {};
      values.key = "labels";
      const response = await SendToApi("accounts/getsettings", values); // this returns the info from the database
      if (response.status === 200) {
        var _labels = [];
        JSON.parse(response.data).labels.forEach((c) => {
          _labels.push(c);
        });
        setAvailableLabels(_labels);
        //console.log(_labels);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var values = {};
      values.key = "predict_clickstotals";
      const response = await SendToApi("accounts/getsettings", values); // this returns the info from the database
      if (response.status === 200) {
        //console.log(response.data);
        setTotalCustomers(JSON.parse(response.data).total_customers);
        //console.log(JSON.parse(response.data).total_customers);
      }
    })();
  }, []);

  // initial setting up of stuff
  useEffect(() => {
    (async () => {
      setFetchedData(false);
      var values = {};
      var response = await SendToApi("predict/getlabelurls", values); // this returns the info from the database
      if (response.status === 200) {
        seturlRows(response.data["rows"]);
        var _totalClicks = 0;
        var _totalNillLabels = 0;
        var _totalNoLabels = 0;
        response.data["rows"].forEach((row) => {
          _totalClicks = parseInt(_totalClicks) + parseInt(row.total);
          if (!row.labels) {
            _totalNillLabels++;
          } else {
            var _labels = JSON.parse(row.labels);

            var _labelFound = false;
            _labels.forEach((label) => {
              if (label.labels != "no label") {
                _labelFound = true;
              }
            });
            if (!_labelFound) {
              //console.log(row.labels);
              _totalNoLabels++;
            }
          }
        });
        //console.log(`total nill labels ${_totalNillLabels}`);
        //console.log(`total no labels ${_totalNoLabels}`);
        //console.log(`total clicks ${_totalClicks}`);
        setNillLabelCount(_totalNillLabels);
        setNoLabelCount(_totalNoLabels);
        setTotalClicks(_totalClicks);
      }
      // get the tree
      values = {};
      values["key"] = "url_split_clicks";
      response = await SendToApi("accounts/getsettings", values); // this returns the info from the database
      if (response.status === 200) {
        setData(JSON.parse(response.data));
        //console.log(JSON.parse(response.data));
        setFetchedData(true);
      }
      values = {};
      values["key"] = "label_totals";
      response = await SendToApi("accounts/getsettings", values); // this returns the info from the database
      if (response.status === 200) {
        var _pieData = [];
        const _labelTotals = JSON.parse(response.data);

        var _total = 0;
        Object.keys(_labelTotals).forEach((key) => {
          const obj = {
            id: key,
            label: key,
            value: _labelTotals[key],
            uids: "",
          };
          _total += obj.value;
          if (key !== "no label") {
            // //console.log(obj);
            _pieData.push(obj);
          }
        });

        setLabelTotals(_labelTotals);
        setPieData(_pieData); //.forEach((label) => {
        setPieTotal(_total);
        setCenterText(`${_total} labels`);
        setNumberOfLabels(Object.keys(_labelTotals).length); // remove the no label
        //});
        setFetchedData(true);
        //console.log(pieData);
        //console.log(labelTotals);
      }
    })();
    return () => {};
  }, []);

  // submit label changes
  async function handleSubmit() {
    var values = {};

    values["labels"] = labelled;

    const response = await SendToApi("predict/submitlabels", values); // this returns the info from the database
    if (response.status === 200) {
      //console.log("new labels applied");
      setLabelSubmitted(true);
    }
  }

  //label is selected
  function selectLabel(values) {
    // this is when the label is selected
    setLabelSubmitted(false);
    //console.log("values");
    //console.log(values);
    // check if labels have been removed - select one then the other then remove them one by one
    setLabel(values);

    const node = findNestedObject(data, "uid", selectedUID);

    if (node) {
      //console.log(node);
      //console.log("existing values");
      //console.log(labelled);
      // set existing after you have checked
    }

    // add labels to row structure
    selectedUID.split(",").forEach((uid) => {
      const id = uid.split("-");

      const depth = id[1];
      const url = id[0];

      var _labelled = {};

      _labelled = labelled[url];

      if (!_labelled) {
        _labelled = {};
        _labelled[depth] = values;
      } else {
        _labelled[depth] = values;
      }

      setLabelled((prevState) => ({
        ...prevState,
        [url]: _labelled,
      }));
    });

    values.forEach((v) => {});

    if (node) {
      //console.log(node.label);
      // loop through the selected labels
      values.forEach((value) => {
        // look for the label in the existing pieData array
        const searchIndex = pieData.findIndex((label) => label.label == value);
        // if its -1 its not currently set
        if (searchIndex === -1) {
          var obj = {
            id: value,
            label: value,
            value: node.total,
            uids: node.uid,
          };

          setPieData((prevState) => [...prevState, obj]);
        } else {
          var pieSlice = [...pieData];
          //console.log(pieSlice[searchIndex].uids);
          //console.log(node.uid);
          //console.log(!pieSlice[searchIndex].uids.includes(node.uid));
          if (pieSlice[searchIndex].uids.includes(node.uid)) {
          } else {
            if (
              // only add if its not already set on this node or none have been set or set in labelTotals for this node
              typeof node.label === "undefined" ||
              !node.label.includes(value)
            ) {
              pieSlice[searchIndex].uids += `${node.uid}`;

              pieSlice[searchIndex].value =
                pieSlice[searchIndex].value + node.total;
              //console.log(pieSlice[searchIndex].label);
              if (pieSlice[searchIndex].label !== "no label") {
                setPieData(pieSlice);
              }
            }
          }
        }
      });
    }
  }
  function ToolTip(obj) {
    return (
      <div
        style={{
          padding: 12,
          background: "#ffffff",
          border: `${obj.color} thin solid`,
          borderRadius: 5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>
            <strong>
              {obj.id}: {obj.value}
            </strong>
          </Typography>
          <Typography>
            ({percentageLabels(obj.value)} of total labels)
          </Typography>
          <Typography>
            ({percentageClicks(obj.value)} of total clicks)
          </Typography>
        </Box>
      </div>
    );
  }
  function percentageLabels(value) {
    return Math.round((100 * value) / pieTotal) + "%";
  }

  function percentageClicks(value) {
    return Math.round((100 * value) / totalClicks) + "%";
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      <Typography color="primary" gutterBottom variant="h6">
        Labeller
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />{" "}
      {!fetchedData && <CircularProgress colour="primary" />}
      <TabPanels>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "space-between",
          }}
          title="Label Summary"
        >
          {pieData && (
            <CTResponsivePie
              data={pieData}
              height="700px"
              width="700px"
              centerText={centerText}
              toolTip={ToolTip}
            />
          )}
          {totalCustomers &&
            totalClicks &&
            clicksPerCustomer &&
            numberOfLabels && (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "row",
                  /*  border: "thin lightgray solid",*/
                  padding: "1rem",
                  flexGrow: 1,
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <MouseIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Total Full URL Clicks"
                        secondary={
                          <Typography variant="h5" color="primary">
                            {parseInt(totalClicks).toLocaleString()}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <PeopleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Total Customers"
                        secondary={
                          <Typography variant="h5" color="primary">
                            {parseInt(totalCustomers).toLocaleString()}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <CalculateIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Clicks per Customer"
                        secondary={
                          <Typography variant="h5" color="primary">
                            {clicksPerCustomer}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <LabelIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Assigned Labels"
                        secondary={
                          <Typography variant="h5" color="primary">
                            {parseInt(numberOfLabels).toLocaleString()}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <LabelImportantIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Label clicks"
                        secondary={
                          <Typography variant="h5" color="primary">
                            {parseInt(pieTotal).toLocaleString()}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>

                  {urlRows && (
                    <>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <HttpIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Total URLS"
                            secondary={
                              <Typography variant="h5" color="primary">
                                {urlRows.length}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <LabelOffIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Urls assigned no labels"
                            secondary={
                              <Typography variant="h5" color="primary">
                                {noLabelCount}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CheckBoxOutlineBlankIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Urls unassigned"
                            secondary={
                              <Typography variant="h5" color="primary">
                                {nillLabelCount}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </>
                  )}
                </List>
              </Box>
            )}
        </Box>
        <div title="Set Labels">
          {availableLabels && (
            <URLTreeView
              labels={availableLabels}
              data={data}
              handleSubmit={handleSubmit}
              urlRows={urlRows}
              selectLabel={selectLabel}
              handleSelect={handleSelect}
              labelled={labelled}
              label={label}
              totals={totals}
              searchOptions={searchOptions}
              setValue={handleNodeSearch}
              expand={expand}
              labelSubmitted={labelSubmitted}
            />
          )}
        </div>
        <div title="URLs">
          {urlRows && (
            <URLTable
              rows={urlRows}
              selectedRows={selectedRows}
              totalClicks={totalClicks}
            />
          )}
        </div>
      </TabPanels>
    </Box>
  );
}
