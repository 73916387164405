import React, { useContext, useEffect } from "react";

import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import GlobalStateContext from "../../globalstate/globalStateContext";
import { Copyright } from "../../components/copyright";
import SendToApi from "../../api/sendtoapi";
import CTForm from "../../components/forms/ctform";
import layout from "./layout.json";
import { purple } from "@mui/material/colors";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { Link, Navigate } from "react-router-dom";
import moment from "moment";
import { Image } from "@mui/icons-material";

export default function Login() {
  const [error, setError] = useState(false);
  const [entryURL, setEntryURL] = useState(false);
  const [tfaRequired, setTfaRequired] = useState(false);
  const [formUsername, setFormUsername] = useState(false);
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    const _entryURL = window.sessionStorage.getItem("entryURL");
    if (_entryURL === "/" || _entryURL === "/login") {
      setEntryURL("/landing");
    } else {
      setEntryURL(_entryURL);
    }
  }, []);

  async function SetUserLoggedIn(token, account_id, user_id) {
    // decode the token
    var decoded = jwt_decode(token);

    const username = decoded.username;

    const dateObject = moment();
    const day = dateObject.format("D");
    const month = dateObject.format("M");
    const year = dateObject.format("Y");
    const today = `${year}-${month}-${day}`;

    sessionStorage.setItem("today", today);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("username", decoded.username);
    sessionStorage.setItem("account_id", account_id);
    sessionStorage.setItem("user_id", user_id);

    global.setUserInfo((userInfo) => ({
      ...userInfo,
      token: token,
      username: username,
      accountId: account_id,
      userId: user_id,
      loggedIn: true,
    }));
  }

  async function handleCode(values) {
    setError(false);
    values["username"] = formUsername; // taken from login not store until confirmed
    const response = await SendToApi("users/tfa", values, false);
    if (!response) {
      global.setStateUplift((prevState) => ({
        ...prevState,
        error: "network error",
      }));
      return false;
    }

    switch (response.status) {
      case 200:
        const token = response.data.token;
        const account_id = response.data.account_id;
        const user_id = response.data.user_id;
        SetUserLoggedIn(token, account_id, user_id);
        break;
      default:
    }
  }

  async function handleLogin(values) {
    setError(false);
    values.passwordConfirmation = "";
    setFormUsername(values["email"]);
    const response = await SendToApi("users/login", values, false); /// not sending token
    if (!response) {
      global.setStateUplift((prevState) => ({
        ...prevState,
        error: "network error",
      }));
      setError(
        "iota-ML server problem - please try later - we will be looking into it"
      );
      return false;
    }
    //console.log(response);
    console.log(response.status);
    switch (response.status) {
      case 200:
        const token = response.data.token;
        const account_id = response.data.account_id;
        const user_id = response.data.user_id;
        SetUserLoggedIn(token, account_id, user_id);
        break;
      case 201:
        setTfaRequired(true);
        break;
      case 400:
        global.setStateUplift((prevState) => ({
          ...prevState,
          error: response.statusText,
        }));
        setError("Incorrect login details");
        break;
      case 401:
        global.setStateUplift((prevState) => ({
          ...prevState,
          error: response.statusText,
        }));
        setError("Login failed - please check email and password are correct");
        break;
      default:
        setError(
          "iota-ML server problem - please try later - we will be looking into it"
        );
        break;
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "#9AD8DF",
      }}
    >
      {global.userInfo.loggedIn && entryURL && (
        <Navigate replace to={entryURL} />
      )}
      <Box
        mt={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#9AD8DF",
        }}
      >
        <Paper elevation={4}>
          <Box
            p={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#9AD8DF",
            }}
          >
            <Avatar sx={{ bgcolor: purple[500] }}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>

            {!tfaRequired &&
              process.env.REACT_APP_CUSTOM_NODE_ENV === "production" && (
                <CTForm
                  submitText="Login"
                  handleSubmit={handleLogin}
                  layout={layout.login}
                />
              )}
            {tfaRequired && (
              <CTForm
                submitText="Login"
                handleSubmit={handleCode}
                layout={layout.code}
              />
            )}
            {process.env.REACT_APP_CUSTOM_NODE_ENV !== "production" && (
              <CTForm
                submitText="Login"
                handleSubmit={handleLogin}
                layout={layout.dev}
              />
            )}
            <Box mt={5}>
              <Link to="/forgot">{"Forgot Password?"}</Link>
            </Box>
            {error && (
              <Box mt={5}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}.
                </Alert>
              </Box>
            )}
            <Box mt={5}>
              <img
                src="iota-logo.png"
                style={{ width: "150px", height: "100%", objectFit: "contain" }}
                alt="logo"
              />
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Paper>
      </Box>
      <div className="bottomView">
        <img className="hero-login" src="Hero4.png" alt="logo" />
      </div>
    </Box>
  );
}

//{global.userInfo.loggedIn && <Navigate replace to="/landing" />}
