// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { Box, Typography } from "@mui/material";
import { ResponsiveFunnel } from "@nivo/funnel";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function CTFunnel(props) {
  const { data, height, width } = props;
  return (
    <Box
      sx={{
        height: height,
        width: width,
        /*border: "lightgrey solid thin",*/
        padding: "1rem",
      }}
    >
      <ResponsiveFunnel
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        valueFormat=">-.4s"
        colors={{ scheme: "nivo" }}
        borderWidth={20}
        labelColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={40}
        motionConfig="wobbly"
      />
    </Box>
  );
}
