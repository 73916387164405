import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";

import { Badge, IconButton } from "@mui/material";
// dialogs
import SettingsDialog from "./dialogs/settingsdialog";
import AlertDialog from "./dialogs/alertdialog";
import NinjaDialog from "./dialogs/ninjadialog";
import TaskDialog from "./dialogs/taskdialog";
// icons

import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { useContext, useEffect, useState } from "react";
import GlobalStateContext from "./../globalstate/globalStateContext";
import UserDialog from "./dialogs/userdialog";
import RealStateContext from "../wss/realState/realStateContext";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";

export default function CTDAppBar() {
  const [settings, setSettings] = useState(false);
  const [task, setTask] = useState(false);
  const [alert, setAlert] = useState(false);
  const [ninja, setNinja] = useState(false);
  const [user, setUser] = useState(false);

  const global = useContext(GlobalStateContext);
  const rt = useContext(RealStateContext);

  const navigate = useNavigate();

  const handleDialog = (setValue, value) => {
    setValue(value);
  };

  function handleAlertClose() {
    rt.checkNotifications();
    handleDialog(setAlert, false);
  }
  useEffect(() => {}, []);

  return (
    <div>
      <Box sx={{}}>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            backgroundColor: "#9AD8DF",
            cursor: "pointer",
          }}
        >
          <Toolbar>
            <Box
              onClick={() => navigate("/landing")}
              sx={{
                flexGrow: 1,
                display: "flex",
                gap: "1rem",
              }}
            >
              <img
                src={`iota-${global.state.application}-logo.png`}
                style={{ width: "72px", height: "72px", objectFit: "contain" }}
                alt="logo"
              />
            </Box>
            {/*
            <IconButton
              aria-label="tasks"
              //onClick={() => handleDialog(setTask, true)}
              disabled={rt.liveTasks.queued > 0 ? false : true}
            >
              <Badge
                sx={{ color: "white" }}
                badgeContent={rt.liveTasks.queued}
                color="primary"
              >
                <AddToQueueIcon
                  color={rt.liveTasks.queued > 0 ? "success" : "disabled"}
                />
              </Badge>
            </IconButton>

            <IconButton
              aria-label="tasks"
              //onClick={() => handleDialog(setTask, true)}
              disabled={rt.liveTasks.count > 0 ? false : true}
            >
              <Badge
                sx={{ color: "white" }}
                badgeContent={rt.liveTasks.count}
                color="primary"
              >
                <TaskAltIcon
                  color={rt.liveTasks.count > 0 ? "success" : "disabled"}
                />
              </Badge>
            </IconButton>
          
            <IconButton
              aria-label="delete"
              onClick={() => handleDialog(setAlert, true)}
              disabled={rt.numberOfNotifications > 0 ? false : true}
            >
              <Badge
                sx={{ color: "white" }}
                badgeContent={rt.numberOfNotifications}
                color="primary"
              >
                <NotificationsIcon
                  color={rt.numberOfNotifications > 0 ? "success" : "disabled"}
                />
              </Badge>
            </IconButton>
             */}
            {/*<IconButton
              aria-label="delete"
              onClick={() => handleDialog(setUser, true)}
            > 
           
              <AccountCircleIcon
                color={global.userInfo.loggedIn ? "success" : "error"}
              />
            </IconButton>*/}

            <IconButton
              aria-label="delete"
              onClick={() => handleDialog(setSettings, true)}
            >
              <SettingsIcon
                color={
                  !global.state.connectionError && global.state.wssConnected
                    ? "success"
                    : "error"
                }
              />
            </IconButton>
            {/*
            <IconButton
              aria-label="api spin"
              onClick={() => handleDialog(setSettings, true)}
            >
              <ChangeCircleRoundedIcon
                className="icon-spin-anti"
                color={
                  !global.state.connectionError && global.state.wssConnected
                    ? "success"
                    : "error"
                }
              />
            </IconButton>
              */}
            {global.userIs("ninja") && (
              <IconButton
                aria-label="delete"
                onClick={() => handleDialog(setNinja, true)}
              >
                <img src={"ninja.png"} width="30" alt="ninja" />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Dialog
        maxWidth="xl"
        open={ninja}
        onClose={() => handleDialog(setNinja, false)}
        aria-labelledby="ninja-dialog"
      >
        <NinjaDialog handleClose={() => handleDialog(setNinja, false)} />
      </Dialog>
      <Dialog
        open={settings}
        onClose={() => handleDialog(setSettings, false)}
        aria-labelledby="settings-dialog"
      >
        <SettingsDialog handleClose={() => handleDialog(setSettings, false)} />
      </Dialog>
      <Dialog
        open={alert}
        onClose={() => handleDialog(setAlert, false)}
        aria-labelledby="alert-dialog"
      >
        <AlertDialog
          notifications={rt.notifications}
          updateNotifications={rt.updateNotifications}
          handleClose={handleAlertClose}
        />
      </Dialog>
      <Dialog
        open={user}
        onClose={() => handleDialog(setAlert, false)}
        aria-labelledby="alert-dialog"
      >
        <UserDialog handleClose={() => handleDialog(setUser, false)} />
      </Dialog>
      <Dialog
        open={task}
        onClose={() => handleDialog(setTask, false)}
        aria-labelledby="alert-dialog"
      >
        <TaskDialog handleClose={() => handleDialog(setTask, false)} />
      </Dialog>
    </div>
  );
}
