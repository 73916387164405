import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

//import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { Button, Box, Divider } from "@mui/material";
import { Copyright } from "../../components/copyright";
import { Navigate } from "react-router-dom";
import { useContext, useState } from "react";
import GlobalStateContext from "../../globalstate/globalStateContext";
import CTDLogo from "../../components/logo/logo";
import { AirlineSeatReclineExtraOutlined } from "@mui/icons-material";

/*
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
*/
export default function Landing() {
  const [app, setApp] = useState(false);

  const global = useContext(GlobalStateContext);
  //console.log(global.userInfo);
  return (
    <>
      {global.userInfo.loggedIn && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
            gap: "1rem",
            position: "relative",
            height: "100vh",
            backgroundColor: "#9AD8DF",
          }}
        >
          <CTDLogo />
          <Divider style={{ width: "80%" }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              margin: "3rem",
              gap: "3rem",
              position: "relative",
            }}
          >
            <Box sx={{ height: "100%", width: "150px" }}>
              <img
                className="hero-image-left"
                src="Hero1.png"
                style={{
                  width: "100%",
                  height: "100%",
                  margins: "auto",
                  objectFit: "cover",
                }}
                alt="logo"
              />
            </Box>
            <Card sx={{ width: 150, height: 400, padding: "1rem" }}>
              <CardHeader title="UPLIFT" />
              <CardMedia
                component="img"
                className="hero-button"
                sx={{ width: "150px", margins: "auto", cursor: "pointer" }}
                image="iota-uplift-logo.png"
                alt="uplift"
                onClick={() => {
                  setApp("uplift");
                }}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Find super-responders, improve ROI. Build an optimised plan.
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ width: 150, height: 400, padding: "1rem" }}>
              <CardHeader title="PREDICT" />
              <CardMedia
                component="img"
                className="hero-button"
                sx={{ width: "150px", cursor: "pointer" }}
                image="iota-predict-logo.png"
                alt="predict"
                onClick={() => {
                  setApp("predict");
                }}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Audience beyond rules. ML powered precision marketing that you
                  control.
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ width: 150, height: 400, padding: "1rem" }}>
              <CardHeader title="TRIBES" />
              <CardMedia
                component="img"
                className="hero-button"
                sx={{ width: "150px", margins: "auto", cursor: "pointer" }}
                image="iota-tribes-logo.png"
                alt="insights"
                onClick={() => {
                  setApp("tribes");
                }}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Pro-active data discovery: a marketers best mate.
                </Typography>
              </CardContent>
            </Card>
            {global.userIs("subjecthero") && (
              <Card sx={{ width: 150, height: 400, padding: "1rem" }}>
                <CardHeader title="SUBJECT HERO" />
                <CardMedia
                  component="img"
                  className="hero-button"
                  sx={{ width: "150px", margins: "auto", cursor: "pointer" }}
                  image="iota-subjecthero-logo.png"
                  alt="insights"
                  onClick={() => {
                    setApp("subjecthero");
                  }}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    Subject Hero functionality coming soon.
                  </Typography>
                </CardContent>
              </Card>
            )}
            <Box sx={{ height: "100%", width: "150px" }}>
              <img
                className="hero-image-right"
                src="Hero3.png"
                style={{
                  width: "100%",
                  height: "100%",
                  margins: "auto",
                  objectFit: "cover",
                }}
                alt="logo"
              />
            </Box>
          </Box>
          <Divider style={{ width: "80%" }} />
          <Button
            variant="contained"
            color="primary"
            aria-label="Load"
            onClick={() => {
              global.logout();
            }}
          >
            logout
          </Button>

          <Box>
            <img
              src="iota-logo.png"
              style={{ width: "100px", height: "100%", objectFit: "contain" }}
              alt="logo"
            />
          </Box>
          <Box>
            <Copyright />
          </Box>

          {app === "uplift" && <Navigate replace to="/uplift" />}
          {app === "predict" && <Navigate replace to="/predict" />}
          {app === "tribes" && <Navigate replace to="/tribes" />}
          {app === "subjecthero" && <Navigate replace to="/subjecthero" />}
        </Box>
      )}
    </>
  );
}
/*      {app === 'insights' && <Navigate replace to="/insights" />}
      

        <Card sx={{ width: 200, height: 500, padding: "1rem" }}>
          <CardHeader title="INSIGHTS" />
          <CardMedia
            component="img"
            sx={{ width: "200px", margins: "auto" }}
            image="insights.png"
            alt="insights"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Profiles, stats and nuggets - 'always on' customer insights.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              sx={{ position: "absolute", bottom: "1rem" }}
              variant="contained"
              color="primary"
              aria-label="insights"
              onClick={() => {
                setApp("insights");
              }}
            >
              Go
            </Button>
          </CardActions>
        </Card>
*/
