// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { Box, Typography } from "@mui/material";
import { ResponsiveHeatMap } from "@nivo/heatmap";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const data = [
  {
    id: "Japan",
    data: [
      {
        x: "Train",
        y: -96358,
      },
      {
        x: "Subway",
        y: -40118,
      },
      {
        x: "Bus",
        y: -52945,
      },
      {
        x: "Car",
        y: 3543,
      },
      {
        x: "Boat",
        y: -29618,
      },
      {
        x: "Moto",
        y: -96037,
      },
      {
        x: "Moped",
        y: -56470,
      },
      {
        x: "Bicycle",
        y: -58415,
      },
      {
        x: "Others",
        y: -63481,
      },
    ],
  },
  {
    id: "France",
    data: [
      {
        x: "Train",
        y: -40564,
      },
      {
        x: "Subway",
        y: 16111,
      },
      {
        x: "Bus",
        y: 47613,
      },
      {
        x: "Car",
        y: -93169,
      },
      {
        x: "Boat",
        y: -50684,
      },
      {
        x: "Moto",
        y: 42408,
      },
      {
        x: "Moped",
        y: 46667,
      },
      {
        x: "Bicycle",
        y: -23929,
      },
      {
        x: "Others",
        y: 72693,
      },
    ],
  },
  {
    id: "US",
    data: [
      {
        x: "Train",
        y: -89670,
      },
      {
        x: "Subway",
        y: 68953,
      },
      {
        x: "Bus",
        y: 46842,
      },
      {
        x: "Car",
        y: 74176,
      },
      {
        x: "Boat",
        y: -51686,
      },
      {
        x: "Moto",
        y: -5357,
      },
      {
        x: "Moped",
        y: -64772,
      },
      {
        x: "Bicycle",
        y: 79501,
      },
      {
        x: "Others",
        y: -94451,
      },
    ],
  },
  {
    id: "Germany",
    data: [
      {
        x: "Train",
        y: 87288,
      },
      {
        x: "Subway",
        y: -57464,
      },
      {
        x: "Bus",
        y: 34612,
      },
      {
        x: "Car",
        y: 49577,
      },
      {
        x: "Boat",
        y: 42723,
      },
      {
        x: "Moto",
        y: 48605,
      },
      {
        x: "Moped",
        y: 8545,
      },
      {
        x: "Bicycle",
        y: 82568,
      },
      {
        x: "Others",
        y: -71584,
      },
    ],
  },
  {
    id: "Norway",
    data: [
      {
        x: "Train",
        y: 18855,
      },
      {
        x: "Subway",
        y: 22348,
      },
      {
        x: "Bus",
        y: -16117,
      },
      {
        x: "Car",
        y: -92017,
      },
      {
        x: "Boat",
        y: 45547,
      },
      {
        x: "Moto",
        y: 7035,
      },
      {
        x: "Moped",
        y: -37115,
      },
      {
        x: "Bicycle",
        y: -96907,
      },
      {
        x: "Others",
        y: 77508,
      },
    ],
  },
  {
    id: "Iceland",
    data: [
      {
        x: "Train",
        y: 52367,
      },
      {
        x: "Subway",
        y: 67574,
      },
      {
        x: "Bus",
        y: 88140,
      },
      {
        x: "Car",
        y: 9981,
      },
      {
        x: "Boat",
        y: -83871,
      },
      {
        x: "Moto",
        y: -23397,
      },
      {
        x: "Moped",
        y: 90861,
      },
      {
        x: "Bicycle",
        y: 8373,
      },
      {
        x: "Others",
        y: 56525,
      },
    ],
  },
  {
    id: "UK",
    data: [
      {
        x: "Train",
        y: 8797,
      },
      {
        x: "Subway",
        y: -576,
      },
      {
        x: "Bus",
        y: 35007,
      },
      {
        x: "Car",
        y: 43079,
      },
      {
        x: "Boat",
        y: 10377,
      },
      {
        x: "Moto",
        y: -85693,
      },
      {
        x: "Moped",
        y: 84142,
      },
      {
        x: "Bicycle",
        y: -45207,
      },
      {
        x: "Others",
        y: -74101,
      },
    ],
  },
  {
    id: "Vietnam",
    data: [
      {
        x: "Train",
        y: 39885,
      },
      {
        x: "Subway",
        y: 96946,
      },
      {
        x: "Bus",
        y: 1491,
      },
      {
        x: "Car",
        y: 34123,
      },
      {
        x: "Boat",
        y: -80440,
      },
      {
        x: "Moto",
        y: 10487,
      },
      {
        x: "Moped",
        y: -49445,
      },
      {
        x: "Bicycle",
        y: -53511,
      },
      {
        x: "Others",
        y: -22344,
      },
    ],
  },
];
export default function CTHeatMap(props) {
  const { data, height, width, legend } = props;
  return (
    <Box
      sx={{
        height: height,
        width: width,
        /*border: "lightgrey solid thin",*/
        padding: "1rem",
      }}
    >
      <ResponsiveHeatMap
        data={data}
        margin={{ top: 150, right: 90, bottom: 60, left: 90 }}
        valueFormat=">-.2s"
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
          legend: "",
          legendOffset: 46,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legend,
          legendPosition: "middle",
          legendOffset: -72,
        }}
        colors={{
          type: "diverging",
          scheme: "red_yellow_blue",
          divergeAt: 0.5,
        }}
        emptyColor="#555555"
        legends={[
          {
            anchor: "bottom",
            translateX: 0,
            translateY: 30,
            length: 400,
            thickness: 8,
            direction: "row",
            tickPosition: "after",
            tickSize: 3,
            tickSpacing: 4,
            tickOverlap: false,
            tickFormat: ">-.2s",
            title: "Value →",
            titleAlign: "start",
            titleOffset: 4,
          },
        ]}
      />
    </Box>
  );
}
