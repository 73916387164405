import { Box, Button, Divider, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GlobalStateContext from "../../../globalstate/globalStateContext";
import { useContext, useEffect, useState } from "react";
import SendToApi from "../../../api/sendtoapi";
import UpliftReport from "./uplift";
import PredictReport from "./predict";
import ScoreReport from "./score";
import TribesReport from "./tribes";

export default function Report() {
  const [type, setType] = useState("");
  const global = useContext(GlobalStateContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const job_token = queryParameters.get("tk");
  const { id } = useParams();
  const navigate = useNavigate();

  const SelectReport = () => {
    switch (type) {
      case "uplift":
        return <UpliftReport id={id} />;

      case "score":
        return <ScoreReport id={id} />;

      case "predict":
        return <PredictReport id={id} />;

      case "tribesdemo":
      case "tribes":
        return <TribesReport id={id} />;

      default:
        break;
    }
    return (
      <>
        <h1>unknown {type}</h1>
      </>
    );
  };
  useEffect(() => {
    (async () => {
      var values = {};
      values.job = id;
      console.log(job_token);
      const response = await SendToApi("jobs/info", values);
      if (response.status !== 200) {
        console.log(response);
        return;
      } else {
        console.log(response.data.type);
        setType(response.data.type);
      }
    })();

    return () => {};
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        width: "90%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Typography color="primary" gutterBottom variant="h6">
          Report {id}
        </Typography>
        <Button
          onClick={() => {
            navigate(`/${global.state.application}/outputs`);
          }}
          component="label"
          variant="contained"
          startIcon={<ArrowBackIcon />}
        >
          back
        </Button>
      </Box>
      <Divider sx={{ marginBottom: "1rem" }} />
      <SelectReport />
    </Box>
  );
}
