import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: "1rem" }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function TabPanels(props) {
  const { children } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="selector tabs">
        {React.Children.map(children, (child) => {
          console.log(child.props);
          return <Tab label={child.props.title} />;
        })}
      </Tabs>
      {React.Children.map(children, (child, i) => {
        //console.log(child);
        return (
          <TabPanel value={value} index={i}>
            {child}
          </TabPanel>
        );
      })}
    </Box>
  );
}
