import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import TabPanels from "../../tabpanel";
import CTResponsiveTreeMap from "../../nivo/treemap";
import SendToApi from "../../../api/sendtoapi";

export default function PredictReport(props) {
  const { id } = props;
  const [data, setData] = useState(false);

  useEffect(() => {
    //console.log("useffect in predict report");
  }, []);

  useEffect(() => {
    (async () => {
      var values = {};
      values.job = id;
      const response = await SendToApi("predict/getreport", values);
      if (response.status !== 200) {
        return;
      } else {
        var _dataChart = [];

        response.data.forEach((element, i) => {
          const _obj = {
            name: element.target_type,
            loc: element.contact_id,
          };
          _dataChart[i] = _obj;
        });

        console.log(JSON.stringify(_dataChart));
        const obj = {
          name: "Responders",
          children: _dataChart,
        };

        setData(obj);
      }
    })();

    return () => {};
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
      }}
    >
      <TabPanels>
        <div title="overview">
          <CTResponsiveTreeMap data={data} height="560px" width="500px" />
        </div>
      </TabPanels>
    </Box>
  );
}
