import {
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SendToApi from "../../../api/sendtoapi";
import CTResponsiveBar from "../../../components/nivo/barchart";
import CTFunnel from "../../../components/nivo/funnel";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MouseIcon from "@mui/icons-material/Mouse";
import HttpIcon from "@mui/icons-material/Http";

import Iconlist, {
  IconListElement,
} from "../../../components/iconlist/iconlist";

export default function Campaigns() {
  const [performanceStartDate, setPerformanceStartDate] = useState(null);
  const [performanceEndDate, setPerformanceEndDate] = useState(null);
  const [leagueStartDate, setLeagueStartDate] = useState(null);
  const [leagueEndDate, setLeagueEndDate] = useState(null);

  const [performanceCampaignData, setPerformanceCampaignData] = useState(false);
  const [leagueCampaignData, setLeagueCampaignData] = useState(false);

  const [openRate, setOpenRate] = useState(false);
  const [clickRate, setClickRate] = useState(false);

  useEffect(() => {
    if (performanceStartDate && performanceEndDate) {
      GetData("performance");
    }
    if (leagueStartDate && leagueEndDate) {
      GetData("league");
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [
    performanceStartDate,
    performanceEndDate,
    leagueStartDate,
    leagueEndDate,
  ]);

  function checkEndDate(value, field) {
    //console.log(value.$d);
    //console.log(value.$d === "Invalid Date");
    //console.log(value.$d instanceof Date);
    if (value.$d instanceof Date && !isNaN(value.$d)) {
      if (field === "performance") {
        setPerformanceEndDate(value.$d.toISOString().split("T")[0]);
      }
      if (field === "league") {
        setLeagueEndDate(value.$d.toISOString().split("T")[0]);
      }
    }
  }

  function compare(a, b) {
    if (a.clickrate > b.clickrate) {
      return -1;
    }
    if (a.clickrate < b.clickrate) {
      return 1;
    }
    return 0;
  }

  function compare2(a, b) {
    if (a.clickrate < b.clickrate) {
      return -1;
    }
    if (a.clickrate > b.clickrate) {
      return 1;
    }
    return 0;
  }

  async function GetData(field) {
    var values = {};
    if (field === "performance") {
      values.starttime = performanceStartDate;
      values.endtime = performanceEndDate;
    }
    if (field === "league") {
      values.starttime = leagueStartDate;
      values.endtime = leagueEndDate;
    }
    values.field = field;
    const response = await SendToApi("predict/getinsightcampaigns", values);
    if (response.status !== 200) {
      //console.log("error");
    } else {
      var _data = [];
      if (!response.data) {
        if (field === "performance") {
          setPerformanceCampaignData('no data');
        }
        if (field === "league") {
          setLeagueCampaignData('no data');
        }
      }
      //console.log(response.data);
      Object.keys(response.data).forEach((key, index) => {
        if (field === "performance") {
          const _obj = {
            id: `step_${key}`,
            value: parseFloat(response.data[key]),
            label: key,
          };
          
          _data[index] = _obj;
        }
        if (field === "league") {
          const _obj = {
            campaign: response.data[key].name,
            clickrate: response.data[key].value,
          };
          _data[index] = _obj;
        }
      });
      
      if (field === "performance") {
        //console.log(_data);
        setPerformanceCampaignData(_data);
        const _openrate = (_data.find(x => x.id === 'step_opened').value / _data.find(x => x.id === 'step_sends').value * 100).toFixed(2);
        setOpenRate(_openrate);

        const _clickrate = (_data.find(x => x.id === 'step_clicked').value / _data.find(x => x.id === 'step_sends').value * 100).toFixed(2);
        setClickRate(_clickrate);

      }
      if (field === "league") {
        // order by percentage
        _data.sort(compare);
        //
        _data.length = 10;
        // flip - greatest at the top
        _data.sort(compare2);
        //console.log(_data);
        setLeagueCampaignData(_data);
      }
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          Performance
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            /*border: "thin lightgray solid",*/
            padding: "1rem",
            justifyContent: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              justifyContent: "top",
            }}
          >
            <DatePicker
              width="200px"
              label="Start Date"
              inputFormat="DD/MM/YYYY"
              value={performanceStartDate}
              maxDate={performanceEndDate ? performanceEndDate : null}
              onChange={(newValue) => {
                setPerformanceStartDate(
                  newValue.$d.toISOString().split("T")[0]
                );
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "350px" }} />
              )}
            />
            <DatePicker
              label="End Date"
              inputFormat="DD/MM/YYYY"
              value={performanceEndDate}
              onChange={(newValue) => checkEndDate(newValue, "performance")}
              minDate={performanceStartDate ? performanceStartDate : null}
              maxDate={dayjs()}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "350px" }} />
              )}
            />
          </Box>
          {performanceCampaignData && performanceCampaignData !== "no data" && (
            <>
              <CTFunnel
                data={performanceCampaignData}
                height="400px"
                width="400px"
              />
              <Iconlist>
                {openRate && (
                  <IconListElement
                    icon={HttpIcon}
                    title="Open Rate"
                    value={openRate}
                    percent
                  />
                )}
                {clickRate && (
                  <IconListElement
                    icon={MouseIcon}
                    title="Click Rate"
                    value={clickRate}
                    percent
                  />
                )}
              </Iconlist>
            </>
          )}
          {performanceCampaignData === "no data" && (
            <Typography variant="h7" color="primary">
              No data found. Please select different dates.
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: "1",
        }}
      >
        <Typography variant="h5" color="primary">
          League Table
        </Typography>
        <Box
          className="uplift-report"
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
         /*   border: "thin lightgray solid",*/
            padding: "1rem",
            justifyContent: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              justifyContent: "top",
            }}
          >
            <DatePicker
              label="Start Date"
              inputFormat="DD/MM/YYYY"
              value={leagueStartDate}
              maxDate={leagueEndDate ? leagueEndDate : null}
              onChange={(newValue) => {
                setLeagueStartDate(newValue.$d.toISOString().split("T")[0]);
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "350px" }} />
              )}
            />
            <DatePicker
              label="End Date"
              inputFormat="DD/MM/YYYY"
              value={leagueEndDate}
              onChange={(newValue) => checkEndDate(newValue, "league")}
              minDate={leagueStartDate ? leagueStartDate : null}
              maxDate={dayjs()}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "350px" }} />
              )}
            />
          </Box>

          {leagueCampaignData && leagueCampaignData !== "no data" && (
            <CTResponsiveBar
              data={leagueCampaignData}
              margin={{ top: 5, right: 10, bottom: 20, left: 250 }}
              label={(d) => `${d.value}%`}
              groupMode="grouped"
              layout="horizontal"
              height="350px"
              width="800px"
              index="campaign"
              keys={["clickrate"]}
              axisLeft={{
                format: (value) => `${value}`,
                legendPosition: "middle",
                legendOffset: -30,
              }}
              axisBottom={{
                format: (value) => `${value}%`,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: 32,
                legendPosition: "middle",
              }}
            />
          )}
          {leagueCampaignData === "no data" && (
            <Typography variant="h7" color="primary">
              No data found. Please select different dates.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
