import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

import SendToApi from "../../api/sendtoapi";
import { useState } from "react";

export default function ApiButton(props) {
  const { endpoint, values = {}, label, responseRequired = false } = props;
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  async function handleBuildClickTables() {
    setLoading(true);
    setResponse(false);
    const response = await SendToApi(endpoint, values); // this tells the server to make the click tables - it does not return anything
    if (response.status === 200) {
      console.log(response.data);
      setResponse(JSON.stringify(response.data));
    }
    setLoading(false);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        onClick={handleBuildClickTables}
      >
        {label}
      </LoadingButton>
      {responseRequired && response}
    </Box>
  );
}
