import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
//import { JsonEditor } from "react-jsondata-editor";
import EditIcon from "@mui/icons-material/Edit";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { purple, blue, green, blueGrey } from "@mui/material/colors";
import styles from "./Index.module.css";

//https://www.npmjs.com/package/react-jsondata-editor/v/2.0.0
import { useEffect, useState } from "react";

const myStyle = {
  theme: {
    color: purple[100],
    hoverColor: "#eaeaea",
  },
  bannerStyle: {
    hoverColor: purple[400],
    fontColor: "white",
  },
  keyStyle: {
    color: "black",
  },
  valueStyle: {
    null: "#939597",
    boolean: "#939597",
    number: "#939597",
    string: "#939597",
  },
  buttonStyle: {
    add: purple[400],
    delete: purple[400],
    update: purple[400],
    cancel: purple[400],
    fontColor: "white",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "50%",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
};

export default function JsonEdit(props) {
  const { title, data, index, onEdit, onSave } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let currentEditObj;

  function textSave(e) {
    setJsonInput(e.target.value);
  }

  function saveJsonString() {
    if (currentEditObj === undefined) {
      setJsonInput("");
    } else {
      setJsonInput(currentEditObj);
    }
  }

  const [jsonInput, setJsonInput] = useState(JSON.stringify(data, null, " "));

  return (
    <div className="jsonEdit">
      <Button
        variant="outlined"
        onClick={handleOpen}
        startIcon={<DataObjectIcon />}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="jsonedit"
          sx={{
            //overflow: "scroll",
            border: "thin gray solid",
            padding: "1rem",
            borderRadius: "1rem",
          }}
        >
          <Box sx={style}>
            <Box
              className="jsonedit"
              sx={{
                overflow: "scroll",
                border: "thin gray solid",
                padding: "1rem",
                borderRadius: "1rem",
              }}
            >
              {/**
              <JsonEditor
                jsonObject={data}
                onChange={(edit) => onEdit(edit, index)}
                theme={myStyle.theme}
                bannerStyle={myStyle.bannerStyle}
                keyStyle={myStyle.keyStyle}
                valueStyle={myStyle.valueStyle}
                buttonStyle={myStyle.buttonStyle}
              />
            */}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{ flexGrow: 1 }}
                variant="contained"
                onClick={() => onSave(index)}
              >
                Save
              </Button>
              <Button
                sx={{ flexGrow: 1 }}
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
